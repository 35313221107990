import AuthCompleteTemplate from './template';
import { Landing } from 'layouts';
import { createTasks } from '../tasks';
import { page } from 'utils/views';

const Template = page({
  Template: AuthCompleteTemplate,
  createTasks,
  withLayout: Landing,
});

export default Template;
